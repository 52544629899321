import { graphql } from "gatsby";
import Link from "@components/Link";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect, useMemo } from "react";
import MarkdownText from "@atoms/MarkdownText/MarkdownText";
import Button from "@atoms/Button/Button";
import Section from "@atoms/Section/Section";
import Footer from "@components/Footer/Footer";
import { useLocale } from "@hooks";
import Header from "@components/Header/Header";
import HeaderNav from "@components/HeaderNavV2/HeaderNav";
import Layout from "@components/layout";
import Seo from "@components/seo";
import { UserLang } from "@constants/common";
import { withLocale, withTracking } from "@hocs";
import { useTracking } from '@hooks';
export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

		aepsyCms {
			markdownContentsDE: markdownContents(locale: "de-CH", filters:  {
				key:  {
					eq: "SIGN_UP_REQUIREMENTS"
				}
			}) {
				content
			}
			markdownContentsEN: markdownContents(locale: "en", filters:  {
				key:  {
					eq: "SIGN_UP_REQUIREMENTS"
				}
			}) {
				content
			}
			markdownContentsFR: markdownContents(locale: "fr-CH", filters:  {
				key:  {
					eq: "SIGN_UP_REQUIREMENTS"
				}
			}) {
				content
			}
			markdownContentsIT: markdownContents(locale: "it", filters:  {
				key:  {
					eq: "SIGN_UP_REQUIREMENTS"
				}
			}) {
				content
			}
		}
    }
`;

const SignupRequirementsPage = ({ data }: any) => {
	const { t } = useTranslation();
	const { currentLanguage } = useLocale();
	const { trackPageView } = useTracking();
	useEffect(() => {
		trackPageView();
	}, []);

	const markdownContent = useMemo(() => {
		switch (currentLanguage) {
			case UserLang.English:
				return data?.aepsyCms?.markdownContentsEN;
			case UserLang.French:
				return data?.aepsyCms?.markdownContentsFR;
			case UserLang.Italian:
				return data?.aepsyCms?.markdownContentsIT;
			case UserLang.German:
				return data?.aepsyCms?.markdownContentsDE;
			default:
				return data?.aepsyCms?.markdownContentsDE;
		}
	}, [data, currentLanguage]);

	const renderContent = () => {
		if (!markdownContent || markdownContent?.length === 0) {
			return <></>;
		}

		return <MarkdownText content={markdownContent[0]?.content} />;
	};

	return (
		<Layout>
			<Seo title={t("sign.up.requirements.title")} />
			<div className="global_theme-green">
				<HeaderNav theme="white" />
				<Section theme="dark">
					<Header
						theme="dark"
						title={t("sign.up.requirements.title")}
						align="center"
						spacing="s"
						size="thin"
					/>
				</Section>

				<Section theme="light" spacing="m" container="large">
					{renderContent()}

					<Section spacing="m">
						<Link to="/">
							<Button label={t("button.back")} />
						</Link>
					</Section>
				</Section>

				<Footer />
			</div>
		</Layout>
	);
};

export default withTracking(withLocale(SignupRequirementsPage));
